<template>
<div>
    <KpiHeader
        v-if="location"

        title="Country Overview"
        :location="location"
        :tabs="tabs"
        :disable_title_meta="true"
    />
    <SelectCountry
        v-if="!$store.getters.market"
    />
    <div v-else>
        <div class="goals-box">
        Goals Reached
        <b class="goal-display">
            <p class="goal-blue p3-letter-spacing">{{ total_goals }}</p>
            <p class="p3-letter-spacing">/</p>
            <p>{{ table.rows.length }}</p>
        </b>
        </div>
        <Table
        v-if="table.render"
        :headers="table.headers"
        :rows="table.rows"

        :bridge-settings="table.bridge_settings"
        :icon-settings="table.icon_settings"
        :decimal-settings="table.decimal_settings"
        :data-settings="{ nullify_zeros: true }"
        :meta-settings="{ is_selectable: false }"
        :sort-settings="{ 'Rank (country)': { asc: true } }"
        class="ms1p5 mt no-all"
        />
    </div>
</div>
</template>

<script>
import KpiHeader from '@/components/ui/KPIheader.vue';
import SelectCountry from '@/components/ui/SelectCountry.vue';
import Table from '@/components/visualisations/table/tablev2/TableV2.vue';

export default {
    props: {
        market: String
    },
    components: {
        KpiHeader,
        SelectCountry,
        Table
    },
    data: function() {
        return {
            location: '/country-overview',
            table: {
                headers: [],
                rows: [],
                render: false,
                icon_settings: {},
                decimal_settings: {},
            },
            total_goals: null
        }
    },
    mounted: function() {
        this.$store.state.commenting.width = 0;
        this.$store.commit('set_routing', true);
        this.get_data();
    },
    destroyed: function() {
        this.$store.state.commenting.width = 4;
    },
    methods: {
        get_data: function() {
            this.table.render = false;
            this.$http.post('/country-overview/kpi', {
                market: this.$store.getters.market
            }).then(
                succ => {
                    const d = succ.data;
                    this.table.headers = [[
                        {'': 1},
                        {'Rank (country)': 1},
                        {'Value': 1},
                        {'vs Goal': 1},
                        {'Trend': 1},
                        {'Diff to best country': 1},
                        {'1st': 1},
                        {'2nd': 1},
                        {'3rd': 1},
                    ]];
                    const keys = [
                        't_sub_area',
                        't_current_country_rank',
                        't_current_country_value',
                        't_current_vs_goal',
                        't_current_trend',
                        't_diff_vs_best',
                        't_rank_one',
                        't_rank_two',
                        't_rank_three'
                    ];
                    
                    this.table.decimal_settings = {
                        global: 1,
                        'Rank (country)': {
                            precision: 0
                        }
                    };
                    this.table.rows = [];
                    d.forEach(row => {
                        if (!row) return;
                        let temp = [];
                        keys.map(key => {
                            if (key.includes('t_current_country_rank')) {
                                temp.push(Number(row[key]));
                            } else {
                                temp.push(row[key]);
                            }
                        });
                        this.total_goals = d[0].t_goals_reached;
                        this.table.rows.push(temp);
                    });
                    this.table.icon_settings = {
                        'Trend': {
                            icon: 'arrow-only',
                            comparison: 0
                        },
                        'vs Goal': {
                            icon: 'circle',
                            comparison: 0,
                        },
                        'Diff to best country': {
                            fix: { post: '%' }
                        },
                        '1st': {
                            tooltip: 'list',
                            column_width: 5
                        },
                        '2nd': {
                            tooltip: 'list',
                            column_width: 5
                        },
                        '3rd': {
                            tooltip: 'list',
                            column_width: 5
                        }
                    };
                    this.table.bridge_settings = {
                        columns: {
                            '1st': { hide: true },
                            '2nd': { hide: true },
                            '3rd': { hide: true }
                        }
                    };
                    this.table.render = true;
                },
                this.$helper.error
            );
        },
        update_view: function(market) {
            this.$store.commit('update_view', {
                market,
                location: `${this.location}/kpi`
            });
        }
    },
    computed :{
        tabs: function() {
            return [
                {name: 'By Country', link: `${this.location}/country`},
                {name: 'KPI List', link: `${this.location}/kpi/${this.$store.getters.market}`},
                {name: 'Benchmark', link: `${this.location}/benchmark`}
            ]
        }
    },
    watch: {
        '$store.getters.market': function(market) {
            this.update_view(market);
            this.get_data();
        }
    }
}
</script>

<style scoped>
.goals-box {
  width: fit-content;
  background-color: #f3f7fb;
  padding: 0.5rem;
  margin: 1rem 0rem 0rem 1rem;
  font-size: 1.2rem;
}

.goal-blue {
  color: #0058a3;
}

.goal-display {
  display: flex;
  margin-left: 2.4rem;
}

.p3-letter-spacing {
  letter-spacing: 0.3rem;
}
</style>
