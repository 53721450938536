var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.location
        ? _c("KpiHeader", {
            attrs: {
              title: "Country Overview",
              location: _vm.location,
              tabs: _vm.tabs,
              disable_title_meta: true,
            },
          })
        : _vm._e(),
      !_vm.$store.getters.market
        ? _c("SelectCountry")
        : _c(
            "div",
            [
              _c("div", { staticClass: "goals-box" }, [
                _vm._v(" Goals Reached "),
                _c("b", { staticClass: "goal-display" }, [
                  _c("p", { staticClass: "goal-blue p3-letter-spacing" }, [
                    _vm._v(_vm._s(_vm.total_goals)),
                  ]),
                  _c("p", { staticClass: "p3-letter-spacing" }, [_vm._v("/")]),
                  _c("p", [_vm._v(_vm._s(_vm.table.rows.length))]),
                ]),
              ]),
              _vm.table.render
                ? _c("Table", {
                    staticClass: "ms1p5 mt no-all",
                    attrs: {
                      headers: _vm.table.headers,
                      rows: _vm.table.rows,
                      "bridge-settings": _vm.table.bridge_settings,
                      "icon-settings": _vm.table.icon_settings,
                      "decimal-settings": _vm.table.decimal_settings,
                      "data-settings": { nullify_zeros: true },
                      "meta-settings": { is_selectable: false },
                      "sort-settings": { "Rank (country)": { asc: true } },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }